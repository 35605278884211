<div class="flex flex-1 flex-col px-5">

  <div class="mb-4">
    <h1 class="font-black text-[20px] text-black">Usuários</h1>
    <h6 class="text-zinc-400 font-light text-sm my-2">Os usuários do sistema podem ser gerenciados aqui</h6>
    <hr />
  </div>

  <div class="w-full flex items-center">
    <div class="flex w-full flex-col">
      <div class="flex gap-2">
        <div class="flex flex-1">

          <label for="simple-search" class="sr-only">Pesquisar</label>
          <div class="relative w-full">
            <div class="absolute inset-y-0 left-2 flex items-center pointer-events-none">
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8.52625 14.4874C7.34723 14.4874 6.19468 14.1378 5.21436 13.4828C4.23404 12.8278 3.46997 11.8967 3.01878 10.8075C2.56759 9.71819 2.44953 8.51958 2.67955 7.36321C2.90957 6.20684 3.47732 5.14465 4.31101 4.31095C5.14471 3.47726 6.2069 2.9095 7.36327 2.67949C8.51964 2.44947 9.71825 2.56752 10.8075 3.01872C11.8968 3.46991 12.8278 4.23398 13.4829 5.2143C14.1379 6.19462 14.4875 7.34717 14.4875 8.52619C14.4875 9.30904 14.3333 10.0842 14.0337 10.8075C13.7341 11.5307 13.295 12.1879 12.7415 12.7414C12.1879 13.295 11.5308 13.7341 10.8075 14.0337C10.0843 14.3333 9.3091 14.4874 8.52625 14.4874ZM8.52625 3.76036C7.58679 3.76036 6.66843 4.03894 5.8873 4.56088C5.10616 5.08282 4.49734 5.82467 4.13783 6.69261C3.77831 7.56056 3.68425 8.51563 3.86752 9.43704C4.0508 10.3584 4.5032 11.2048 5.1675 11.8691C5.8318 12.5334 6.67817 12.9858 7.59958 13.1691C8.52099 13.3524 9.47605 13.2583 10.344 12.8988C11.2119 12.5393 11.9538 11.9305 12.4757 11.1493C12.9977 10.3682 13.2763 9.44982 13.2763 8.51036C13.2763 7.25058 12.7758 6.0424 11.885 5.1516C10.9942 4.26081 9.78603 3.76036 8.52625 3.76036Z"
                  fill="#757575" />
                <path
                  d="M15.8333 16.427C15.7553 16.4274 15.678 16.4122 15.606 16.3823C15.5339 16.3523 15.4686 16.3084 15.4137 16.2528L12.1442 12.9833C12.0393 12.8707 11.9822 12.7218 11.9849 12.568C11.9876 12.4142 12.0499 12.2674 12.1587 12.1586C12.2675 12.0499 12.4143 11.9875 12.5681 11.9848C12.7219 11.9821 12.8708 12.0392 12.9833 12.1441L16.2529 15.4137C16.3641 15.525 16.4266 15.6759 16.4266 15.8333C16.4266 15.9906 16.3641 16.1415 16.2529 16.2528C16.1981 16.3084 16.1327 16.3523 16.0607 16.3823C15.9886 16.4122 15.9113 16.4274 15.8333 16.427Z"
                  fill="#757575" />
              </svg>
            </div>
            <input type="search" #searchBox (keyup)="search(searchBox.value)" class="input-field pl-10"
              placeholder="Pesquisar..." style="padding-left: 30px; box-sizing: border-box;" />
          </div>
        </div>
        <div class="flex">
          <button type="submit" [matMenuTriggerFor]="departmentsMenu"
            class="flex justify-center items-center bg-white gap-2 p-2.5 ms-2 text-sm font-medium text-gray-900  rounded-lg global-filter">
            <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6.33333 10H9.66667V8.33333H6.33333V10ZM0.5 0V1.66667H15.5V0H0.5ZM3 5.83333H13V4.16667H3V5.83333Z"
                fill="#667085" />
            </svg>
            <span>
              {{labelDepartment ?? 'Departamentos'}}
            </span>
          </button>

          <button type="submit" [matMenuTriggerFor]="roles"
            class="flex justify-center items-center bg-white gap-2 p-2.5 ms-2 text-sm font-medium text-gray-900  rounded-lg global-filter">
            <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6.33333 10H9.66667V8.33333H6.33333V10ZM0.5 0V1.66667H15.5V0H0.5ZM3 5.83333H13V4.16667H3V5.83333Z"
                fill="#667085" />
            </svg>
            <span>
              {{labelRoles ?? 'Permissões'}}
            </span>
          </button>

          <!-- departments -->
          <mat-menu #departmentsMenu="matMenu">
            <button mat-menu-item (click)="searchGetListByDepartment(null)">Todos</button>
            @for (department of departments; track $index) {
            <button mat-menu-item (click)="searchGetListByDepartment(department)">{{ department.name }}</button>
            }
          </mat-menu>

          <!-- roles -->
          <mat-menu #roles="matMenu">
            <button mat-menu-item (click)="searchGetListByPermission(null)">Todos</button>
            <button mat-menu-item (click)="searchGetListByPermission(roleAdmin)">Administrador</button>
            <button mat-menu-item (click)="searchGetListByPermission(roleSupervisor)">Supervisor</button>
            <button mat-menu-item (click)="searchGetListByPermission(roleAttendant)">Atendente</button>
          </mat-menu>
          <button
            [matTooltip]="totalUsers >= maxUsers ? 'Máximo de usuários atingidos' : 'Adicionar usuário'"
            class="ml-4 w-[200px] h-[46px] flex justify-center items-center rounded-lg bg-[#4213F6] text-white font-normal  hover:bg-[#1340f6]"
            [disabled]="totalUsers >= maxUsers "
            [ngClass]="{'bg-slate-400': totalUsers >= maxUsers, 'hover:bg-slate-500': totalUsers >= maxUsers}"
            (click)="showAddUser()">+ Adicionar</button>
        </div>
      </div>
    </div>
  </div>

  <content-loading-list *ngIf="loadingContent"></content-loading-list>

  <div class="w-full max-h-[calc(100vh-240px)] flex flex-wrap gap-[12px] mt-4"
    *ngIf="!loadingContent && pager.total > 0">
    <div class="w-full max-h-[calc(100vh-240px)] flex flex-wrap gap-[12px]">
      <div class="inline-block min-w-full py-2 align-middle">
        <div
          class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg bg-white border-gray-100 border-2">
          <table class="min-w-full divide-gray-100 divide-y-[3px]">
            <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-light text-slate-500">Nome/Email</th>
                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-light text-slate-500">Departamento</th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-light text-slate-500">Permissões</th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-light text-slate-500">Dias/Horário de Trabalho
                </th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-light text-slate-500">Bloqueio</th>
                <th scope="col" class="relative py-3.5 pl-3 pr-4">
                  <span class="sr-only"></span>
                </th>
              </tr>
            </thead>
            <tbody class="divide-gray-100 divide-y-[3px] bg-white">
              @for (item of pager.list; track $index) {
              <tr class="rounded-none">
                <td class="whitespace-nowrap px-3 py-4">
                  <div class="flex gap-2">
                    <div>
                      <img class="w-10 h-10 rounded-full" [src]="getUserImage(item)" />
                    </div>

                    <div class="flex flex-col">
                      <div class="font-light">
                        {{item.name}}
                      </div>
                      <div>
                        <span class="text-zinc-400 font-light text-xs">{{item.email}}</span>
                      </div>
                    </div>
                  </div>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  <p [matTooltip]="getDepartmentName(item).tooltip">{{ getDepartmentName(item).display }}</p>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm">
                  @if (!!item.roles && item.roles.length > 0) {
                  @for (role of item.roles; track $index) {
                  <span [ngClass]="getRoleClass(role)" class="px-[10px] py-[5px] rounded-[5px] mr-[10px] flex-wrap">
                    {{ role === 'ADMIN' ? 'Administrador' : role === 'SUPERVISOR' ? 'Supervisor' : role === 'ATTENDANT'
                    ? 'Atendente' : '-' }}
                  </span>
                  }
                  } @else {
                  <span class="px-[10px] py-[5px] rounded-[5px] mr-[10px] flex-wrap bg-gray-200 text-gray-600">
                    Sem permissão
                  </span>
                  }
                </td>
                <td class="px-3 py-4 text-sm">
                  <span class="text-gray-600">{{ getDaysWeek(item)}}</span><br />
                  <span class="text-gray-500">{{ getHours(item) }}</span>
                </td>

                <td>
                  <mat-icon class="cursor-pointer" (click)="toggleUserBlockStatus(item)"
                    [ngClass]="item.blocked ? 'mat-warn' : 'mat-primary'">
                    {{ item.blocked ? 'lock' : 'lock_open' }}
                  </mat-icon>
                </td>


                <td class="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                  <div class="inline-block text-left" x-data="{ menu: false }">
                    <button x-on:click="menu = ! menu" type="button"
                      class="flex items-center text-gray-400 hover:text-gray-600 focus:outline-none" id="menu-button"
                      aria-expanded="true" aria-haspopup="true">
                      <span class="sr-only"></span>
                      <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                        aria-hidden="true">
                        <path
                          d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
                      </svg>
                    </button>
                    <div x-show="menu" x-on:click.away="menu = false"
                      class="origin-top-right absolute right-20 w-40 rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
                      role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                      <div class="" role="none">
                        <a href="javascript:void(0)"
                          class="text-gray-500 font-medium hover:text-gray-900 hover:bg-gray-50 block px-4 py-2 text-sm"
                          role="menuitem" tabindex="-1" id="menu-item-0" (click)="edit(item)">
                          Editar
                        </a>
                      </div>
                      <div class="" role="none">
                        <a href="javascript:void(0)"
                          class="text-gray-500 font-medium hover:text-gray-900 hover:bg-gray-50 block px-4 py-2 text-sm"
                          role="menuitem" tabindex="-1" id="menu-item-0" (click)="delete(item)">
                          Remover
                        </a>
                      </div>
                      <div class="" role="none" *ngIf="verifyAdmin()">
                        <a href="javascript:void(0)"
                          class="text-gray-500 font-medium hover:text-gray-900 hover:bg-gray-50 block px-4 py-2 text-sm"
                          role="menuitem" tabindex="-1" id="menu-item-0" (click)="resetPassword(item)">
                          Alterar senha
                        </a>
                      </div>
                      <div class="" role="none" *ngIf="verifyAdmin()">
                        <a href="javascript:void(0)"
                          class="text-gray-500 font-medium hover:text-gray-900 hover:bg-gray-50 block px-4 py-2 text-sm"
                          role="menuitem" tabindex="-1" id="menu-item-0" (click)="toggleUserBlockStatus(item)">
                          {{ item.blocked ? 'Desbloquear' : 'Bloquear' }}
                        </a>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              }
              <tr class=""></tr>
            </tbody>
          </table>
          <!-- <hr class=""/> -->
          <paginator *ngIf="pager.total > pager.perPage" class="flex justify-content-center mt-3 mb-3" [pager]="pager"
            (onPager)="loadPage($event)">
          </paginator>
        </div>
      </div>

    </div>
  </div>

  <empty-records-component *ngIf="!loadingContent && pager.total == 0" [message]="'Não existe esse usuário'"
    [subMessage]="'Adicionar usuário'" [labelButton]="'+ Adicionar'" (emitEvent)="showAddUser()">
  </empty-records-component>

</div>